body {
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.numberCircle {
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 0px;
    border-radius: 80%;
    font-size: 50px;
    min-width: 120px;
    min-height: 120px;
}

.numberCircle span {
    display: inline-block;
    padding-top: 50%;
    padding-bottom: 50%;
    margin-left: 1px;
    margin-right: 1px;
}

/* Background Colors */
.clrGreen {
    background: #739072;
}

.clrRed {
    background: #e78895;
}
